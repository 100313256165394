.SelectContainer {
	background-color: var(--color-primary-4);
	border: 1px solid var(--color-secondary-4);
	border-radius: var(--corner-size--base);
	padding: var(--spacing--md);
	text-align: left;
	flex-direction: row-reverse;

	&__isFocused {
		border-color: var(--color-primary-5);
	}

	&__isSelected {
		border-color: var(--color-primary-5);
	}

	&__error {
		border-color: var(--color-supportive-1);
	}
}
.FixedIconContainer {
	position: relative;
	pointer-events: none;
	& .FixedIcon {
		position: absolute;
		z-index: 1;
		background-color: white;
		min-width: 36px;
		min-height: 36px;
		left: 18px;
		top: 17px;
	}
}

.SelectContainer__placeholder {
	color: var(--color-secondary-2);
}

.SelectContainer__menu {
	background-color: var(--color-primary-4);
	border: 1px solid var(--color-secondary-5);
	border-radius: var(--corner-size--base);
	margin-top: var(--spacing--xs);
}

.SelectContainer__option {
	background-color: white;
	padding: 22px 27px;
	text-align: left;
	border-left: 2px solid transparent;
	box-sizing: border-box;

	&_selected {
		background-color: var(--color-primary-1);
		color: var(--color-primary-4);
	}

	&_focused {
		background-color: var(--color-secondary-5);
		border-left: 2px solid var(--color-primary-1);
	}
}

.SelectField__errorMessage {
	color: var(--color-supportive-1);
	font-size: var(--font-size--2xs);
	margin-top: var(--spacing--sm);
	text-align: left;
}
