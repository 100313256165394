.InputTextFieldComponent {
	// set margins because of box-shadow instead of borders in component
	margin-left: 1px;
	margin-right: 1px;
	& label {
		border-radius: var(--corner-size--base);

		/* Hide the spin buttons in WebKit browsers */
		& input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Hide spin buttons in Firefox */
		& input[type='number'] {
			-moz-appearance: textfield;
		}
	}
}
