.packages {
	background-color: var(--color-primary-5);

	&__topSection {
		padding: var(--spacing--xl) 0 var(--spacing--xl) var(--spacing--lg);
	}
	&__bottomSection {
		padding: var(--spacing--xl) 0 var(--spacing--xl) var(--spacing--lg);
		& button {
			color: var(--color-primary-4, white);
			&:hover {
				color: var(--color-primary-4-50);
			}
		}
	}
}
