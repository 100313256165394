.ProductCalculatorPage {
	&__HeaderComponent {
		background-color: var(--color-secondary-6);
		& Header {
			background-color: var(--color-secondary-6);
		}
		&__packages {
			background-color: var(--color-primary-5);
			& Header {
				background-color: var(--color-primary-5);
			}
		}
	}
}
