.PageTemplate {
	// height: calc(100vh - 63px - 128px); //-header 63 && -footerNav 128
	height: calc(100vh - 63px); //-header 63
	width: inherit;
	overflow-y: auto;

	&--full-height {
		height: calc(100vh - 63px);
	}

	&__Form {
		width: inherit;
		height: inherit;

		&__Headline {
			margin-bottom: var(--spacing--xl, 2rem);
		}

		&__Content {
			display: flex;
			flex-direction: column;
			gap: var(--spacing--md, 1rem);

			&--horizontal {
				flex-direction: row;
			}
		}
	}
	&__navigation {
		display: flex;
		max-width: var(--input-width--max--md);
		gap: var(--spacing--xs, 0.5rem);
	}
}
