.PackageTile {
	color: var(--color-primary-5, #0f195a);
	margin: 0;
	padding: var(--spacing--xl) var(--spacing--lg) var(--spacing--4xl) var(--spacing--lg);
	// height: calc(576.12px - (var(--spacing--4xl) * 2)); //128px

	background-color: var(--color-primary-4);
	border-radius: var(--spacing--xl);

	&_header {
		display: flex;
		flex-direction: column;

		align-items: flex-start;
		gap: 10px;

		&_title {
			font-weight: var(--font-weight--light, 200);
		}

		&_price {
			height: var(--spacing--2xl);
			color: var(--color-primary-1, #1c40ff);
			font-size: var(--spacing--xl);
			font-weight: var(--font-weight--light, 200);
			line-height: 110%;
			display: flex;
			align-items: center;
		}

		&_discount {
			display: flex;
			align-items: center;
			height: 25px;
			color: var(--color-secondary-2, #5f5f64);
		}
	}

	&_coverages {
		margin: 23px -17px;

		& li {
			text-decoration: underline;

			&.notActive {
				color: var(--color-secondary-3, #aaa);
			}
		}

		&_button {
			background: none;
			border: none;
			padding: 0;
			cursor: pointer;
			width: 100%;
			text-align: left;
		}
	}
}
