// navigation buttons
.ButtonNavigation {
	& button {
		&[type='button'] {
			// back btn
			& svg path {
				fill: var(--color-primary-5);
			}
			margin-top: var(--spacing--md, 1rem);
			padding: 0;
			height: var(--spacing--2xl, 48px);
			width: var(--spacing--2xl, 48px);
			@media only screen and (min-width: 744px) {
				height: var(--spacing--4xl, 64px);
				width: var(--spacing--4xl, 64px);
			}
		}
	}
	&__submit {
		flex-grow: 1;
		& button {
			&[type='button'] {
				// submit btn
				margin-top: var(--spacing--md, 1rem);
				width: 100%;
				max-width: var(--input-width--max--md);
			}
		}
	}
	&.package > button {
		// back btn package
		color: var(--color-primary-4);
		margin-bottom: auto;
		margin-top: var(--spacing--md, 1rem);
		margin-left: var(--spacing--md, 1rem);

		&[type='button'] {
			color: var(--color-primary-4);
			height: auto;
			width: auto;

			& svg {
				& g {
					stroke: var(--color-primary-4);
				}
				& path {
					fill: none;
				}
			}
		}
	}
}
