.ToggleLink {
	margin-top: 8px;
	margin-bottom: 24px;
	display: flex;
	align-items: self-end;
	cursor: pointer;

	& .label {
		color: var(--color-primary-1);
		padding-left: 4px;
		flex-grow: 1;
	}

	svg.Chevron {
		transition: transform 0.3s ease;
		width: 2em;
		height: 2em;
		margin: -0.4em 1em -0.4em 0;
		&.open {
			transform: rotate(-180deg);
		}
	}
}
