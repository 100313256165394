@import '@almbrand/styles/dist/media-mixins.scss';

.IframeComponent {
	&__wrapper {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - 80px - 96px); //subtract header height and footer

		@include mq('md') {
			height: calc(100vh - 80px - 114px); //subtract header height and footer
		}
	}

	&__content {
		flex: 1;
		align-content: center;
		justify-items: center;
	}

	&__spinner {
		position: absolute;
		top: 40%;
		left: 50%;
		translate: -50% -50%;
	}

	&__footer {
		position: relative;
		width: 100vw;
		left: -50vw;
		margin-left: 50%;
	}
}
