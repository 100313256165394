// checkBox copied from abg-component-library v. 1.0.18
.Checkbox {
	cursor: pointer;
	font-size: var(--font-size--sm, 18px);
	display: inline-flex;
	flex-direction: row-reverse;
	align-items: flex-start;
	justify-content: flex-end;

	& .ContentWrapper {
		padding: 0 10px;
		cursor: auto;
		& p {
			padding-bottom: 10px;
		}
		& ul {
			padding: 0 40px;
		}
	}
	& .Checkbox_input {
		opacity: 0;
		cursor: pointer;

		&:checked ~ .Checkbox_checkmark {
			background-color: var(--color-primary-1);

			&:after {
				display: block;
			}
		}

		&:disabled ~ .Checkbox_checkmark {
			background-color: var(--color-secondary-4);
			border: solid 2px var(--color-secondary-4);
		}

		& .ContentWrapper {
			padding: 0 10px;
			cursor: auto;
			& p {
				padding-bottom: 10px;
			}
			& ul {
				padding: 0 40px;
			}
		}
	}

	& .Checkbox_checkmark {
		min-height: 25px;
		min-width: 25px;
		background-color: White;
		border: solid 2px var(--color-primary-1);
		position: relative;

		&:hover {
			background-color: var(--color-primary-1-25);
		}

		&:after {
			position: absolute;
			display: none;
			content: '';
			left: 8px;
			top: 3px;
			width: 7px;
			height: 12px;
			border: solid white;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
